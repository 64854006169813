import React, { Component } from 'react';

export default class DataTableOverviewCode extends Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    window.___navigate('/components/dialogs/overlay/design');
  }

  render() {
    return <div />;
  }
}
